import React, { useState, useEffect } from 'react'
import { Form } from 'semantic-ui-react'
import { navigate } from 'gatsby'
// custom
import { urlTelephone, isTelephone } from './helpers/utils'

export default function FromQueryMenu() {
  const [tel, setTel] = useState('')
  const whiteListNumbers = process.env.GATSBY_FAUNA_ADMIN_PAGE_KEYS.split(',')
  const isWhiteListNumber =
    process.env.GATSBY_FAUNA_ADMIN_PAGE_KEYS.split(',').indexOf(tel) >= 0

  useEffect(() => {
    if (urlTelephone) {
      setTel(urlTelephone)
    }
  }, [])

  return (
    <Form
      onSubmit={e => {
        if (isWhiteListNumber) {
          navigate(`/list`)
          return
        }
        navigate(`/menu?tel=${tel}`)
      }}
      size="massive"
      style={{
        maxWidth: `20rem`,
        height: `100vh`,
        display: `flex`,
        flexDirection: 'column',
        margin: `0 auto`,
        justifyContent: `center`,
      }}
    >
      <Form.Input
        placeholder="telephone"
        name="telephone"
        size="massive"
        type="number"
        autoComplete="off"
        defaultValue={isTelephone(urlTelephone) ? urlTelephone : ``}
        onChange={e => setTel(e.target.value)}
        autoFocus // eslint-disable-line
      />
      <Form.Button
        color="black"
        content="open menu"
        style={{ marginTop: `1rem`, width: `100%` }}
        size="massive"
        disabled={!isWhiteListNumber && tel.length > 0 && tel.length < 8}
      />
    </Form>
  )
}
