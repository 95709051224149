import React from 'react'
// custom
import Layout from '../components/user-order/Layout'
import FromQueryMenu from '../components/user-order/FromQueryMenu'

export default function QueryPage() {
  return (
    <Layout>
      <FromQueryMenu />
    </Layout>
  )
}
